import {
    MESSAGES_LIST_FETCH_STARTED, MESSAGES_LIST_FETCH_SUCCESS, MESSAGES_LIST_FETCH_ERROR, MESSAGES_LIST_DELETE_STARTED, MESSAGES_LIST_DELETE_SUCCESS,
    MESSAGES_LIST_DELETE_ERROR
} from '../actionsSP/messagesActions';


const defaultState = {
    rows: [],
    loading: false,
};

function messagesReducersSP(state = defaultState, action) {
    switch (action.type) {
        case MESSAGES_LIST_FETCH_STARTED:
            return Object.assign({}, state, {
                ...state,
                loading: true,
            });
        case MESSAGES_LIST_FETCH_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                rows: action.payload.rows,
            });
        case MESSAGES_LIST_FETCH_ERROR:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                rows: [],
            });
        case MESSAGES_LIST_DELETE_STARTED:
            return Object.assign({}, state, {
                ...state,
                loading: true,
            });
        case MESSAGES_LIST_DELETE_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                modalOpen: false,
            });
        case MESSAGES_LIST_DELETE_ERROR:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                modalOpen: false,
            });

        default:
            return state;
    }
}

export default messagesReducersSP;