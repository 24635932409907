import React, { Suspense } from "react"
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { ToastContainer } from 'react-toastify'; /*pop up notification messages */
import { HashRouter } from 'react-router-dom';

import { AdminRoute, AuthRoute } from './RouteComponents';

/* PAGES */
import ErrorPage from '../pages/error';
import Login from '../pages/login/Login';
import LoginForPTPClients from '../pages/login/LoginForPTPClients';

/* COMPONENTS */
import Loading from "./Loading/Loading"
import LayoutComponent from '../components/Layout';

/* CSS */
import '../styles/theme.scss';

/*const PrivateRoute = ({ dispatch, component, ...rest }) => {
  //if (!Login.isAuthenticated(JSON.parse(localStorage.getItem('authenticated')))) {
  //    dispatch(logoutUser());
  //    return (<Redirect to="/login"/>)
  //} else {
  return ( 
    <Route {...rest} render={props => (React.createElement(component, props))} />
  );
  //}
};*/

const CloseButton = ({ closeToast }) => <i onClick={closeToast} className="la la-close notifications-close" />


class App extends React.Component {

  render() {
    return (
      <div>
        <Suspense fallback={<Loading />}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            pauseOnHover
            hideProgressBar
            closeButton={<CloseButton />}
          />
            <HashRouter>
              <Switch>
                <Route path="/" exact render={() => <Redirect to="/login" />} />
                <AdminRoute path="/admin" currentUser={this.props.currentUser} dispatch={this.props.dispatch} component={LayoutComponent} />
                <AuthRoute path="/login" exact currentUser={this.props.currentUser} component={Login}/>
                <AuthRoute path="/login-clients" exact currentUser={this.props.currentUser} component={LoginForPTPClients}/>
                <Route path="/error" exact component={ErrorPage} />
              </Switch>
            </HashRouter>
        </Suspense>
      </div>

    );
  }
}

// Redux
const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(App);
