import {
  getRaces, deleteRace, updateLadbrokesId, updateLadbrokesPrices, updateAllLadbrokesPrices, sendDailySelection,
  sendNextBet, automateToday, sendSilverSelection, automateRaceSelectionsSilver, automateRaceSelectionsGold, generateRaceSelectionsPlatinum, deleteTodayRaces, showTodayRaces,
  autoSilverResults, autoGoldResults, updateAllLadbrokesOPrices, sendGoldSelection, getAllRodRules, updateAllTrackCondition, removeFromGold, removeLowPTP, rmvABND, updatePriceFromPTP, fetchRaces, fetchRacesSp
} from '../../config/configSP';
import { toast } from 'react-toastify';

export const RACESSP_LIST_FETCH_STARTED = 'RACESSP_LIST_FETCH_STARTED';
export const RACESSP_LIST_FETCH_SUCCESS = 'RACESSP_LIST_FETCH_SUCCESS';
export const RACESSP_LIST_FETCH_ERROR = 'RACESSP_LIST_FETCH_ERROR';
export const RACESSP_FETCH_STARTED = 'RACESSP_FETCH_STARTED';
export const RACESSP_FETCH_SUCCESS = 'RACESSP_FETCH_SUCCESS';
export const RACESSP_FETCH_ERROR = 'RACESSP_FETCH_ERROR';
export const RACESSP_LIST_DELETE_STARTED = 'RACESSP_LIST_DELETE_STARTED';
export const RACESSP_LIST_DELETE_SUCCESS = 'RACESSP_LIST_DELETE_SUCCESS';
export const RACESSP_LIST_DELETE_ERROR = 'RACESSP_LIST_DELETE_ERROR';
export const RACESSP_LIST_OPEN_CONFIRM = 'RACESSP_LIST_OPEN_CONFIRM';
export const RACESSP_LIST_CLOSE_CONFIRM = 'RACESSP_LIST_CLOSE_CONFIRM';
export const REMOVE_GOLD_STARTED = 'REMOVE_GOLD_STARTED';
export const REMOVE_GOLD_SUCCESS = 'REMOVE_GOLD_SUCCESS';
export const REMOVE_GOLD_ERROR = 'REMOVE_GOLD_ERROR';


function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: (data) => async (dispatch) => {
    try {
      dispatch({
        type: RACESSP_LIST_FETCH_STARTED,
      });

      const response = await getRaces(data)
      console.log(response)
      dispatch({
        type: RACESSP_LIST_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: RACESSP_LIST_FETCH_ERROR,
      });
    }
  },

  fetchRaces: () => async (dispatch) => {
    try {
      dispatch({
        type: RACESSP_FETCH_STARTED,
      });

      const response = await fetchRacesSp()
      console.log(response)
      dispatch({
        type: RACESSP_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: RACESSP_FETCH_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: RACESSP_LIST_DELETE_STARTED,
      });

      await deleteRace({ 'race_id': id })

      dispatch({
        type: RACESSP_LIST_DELETE_SUCCESS,
      });

    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: RACESSP_LIST_DELETE_ERROR,
      });
    }
  },

  removeGold: (id) => async (dispatch) => {
    try {
      dispatch({
        type: REMOVE_GOLD_STARTED,
      });

      await removeFromGold({ 'race_id': id })

      dispatch({
        type: REMOVE_GOLD_SUCCESS,
      });

    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: RACESSP_LIST_DELETE_ERROR,
      });
    }
  },

  doOpenConfirm: (id) => async (dispatch) => {
    dispatch({
      type: RACESSP_LIST_OPEN_CONFIRM,
      payload: {
        id: id
      },
    });
  },

  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: RACESSP_LIST_CLOSE_CONFIRM,
    });
  },

  getLadbrokesIds: () => async (dispatch) => {
    try {
      await updateLadbrokesId().then(res => {
        toast.success('Ladbrokes Ids updated successfully');
      })
    } catch (error) {
      toast.error('Error getting Ladbrokes Id. Please contact support')
    }
  },

  getLadbrokesPrices: () => async (dispatch) => {
    try {
      await updateLadbrokesPrices().then(res => {
        toast.success('Ladbrokes Prices updated successfully');
      })
    } catch (error) {
      toast.error('Error getting Ladbrokes Prices. Please contact support')
    }
  },

  updateTrackConsdition: () => async (dispatch) => {
    try {
      await updateAllTrackCondition().then(res => {
        if (res.status === 200) {
          toast.success('Track Condition updated successfully');
        } else {
          toast.error('Error updating track conditions. Please contact support')
        }
      })
    } catch (error) {
      toast.error('Error updating track conditions. Please contact support')
    }
  },

  getAllLadbrokesPrices: () => async (dispatch) => {
    try {
      await updateAllLadbrokesPrices().then(res => {
        toast.success('All Ladbrokes Prices updated successfully');
      })
    } catch (error) {
      toast.error('Error getting All Ladbrokes Prices. Please contact support')
    }
  },

  getAllLadbrokesOPrices: () => async (dispatch) => {
    try {
      await updateAllLadbrokesOPrices().then(res => {
        toast.success('All Ladbrokes Opening Prices updated successfully');
      })
    } catch (error) {
      toast.error('Error getting All Ladbrokes Opening Prices. Please contact support')
    }
  },

  getAllRodRules: () => async (dispatch) => {
    try {
      await getAllRodRules().then(res => {
        toast.success('Rod Rules updated successfully');
      })
    } catch (error) {
      toast.error('Error with Rod Rules. Please contact support')
    }
  },

  removeLowPTP: () => async (dispatch) => {
    try {
      await removeLowPTP().then(res => {
        toast.success('Updated successfully');
      })
    } catch (error) {
      toast.error('Error updating.')
    }
  },

  removeABND: () => async (dispatch) => {
    try {
      await rmvABND().then(res => {
        toast.success('ABND RACES REMOVED');
      })
    } catch (error) {
      console.log(error);
      toast.error(error)
    }
  },

  updatePrPTP: () => async (dispatch) => {
    try {
      await updatePriceFromPTP().then(res => {
        toast.success('Prices updated from PTP');
      })
    } catch (error) {
      toast.error(error);
    }
  },

  sendDailySelection: () => async (dispatch) => {
    try {
      await sendDailySelection().then(res => {
        toast.success('Daily Selection are sent successfully');
      })
    } catch (error) {
      toast.error('Error sending Daily Selection. Please contact support')
    }
  },

  sendSilverSelection: () => async (dispatch) => {
    try {
      await sendSilverSelection().then(res => {
        toast.success('SILVER Selection are sent successfully');
      })
    } catch (error) {
      toast.error('Error sending SILVER Selection. Please contact support')
    }
  },

  sendGoldSelection: () => async (dispatch) => {
    try {
      await sendGoldSelection().then(res => {
        toast.success('GOLD Selection are sent successfully');
      })
    } catch (error) {
      toast.error('Error sending GOLD Selection. Please contact support')
    }
  },



  sendNextBet: () => async (dispatch) => {
    try {
      await sendNextBet().then(res => {
        toast.success('Next bet sent successfully');
      })
    } catch (error) {
      toast.error('Error sending Next bet. Please contact support')
    }
  },

  automateToday: () => async (dispatch) => {
    try {
      await automateToday({ time: null }).then(res => {
        toast.success('CLIENTS WILL RECEIVE NOTIFICATIONS AUTOMATICALLY AS SCHEDUALED');
      })

    } catch (error) {
      toast.error('Error launching daily automation. Please contact support')
    }
  },

  deleteTodayRaces: () => async (dispatch) => {
    try {
      await deleteTodayRaces().then((res) => {
        toast.success('Races deleted Successfully');
      })
    } catch (error) {
      toast.error('Error deleting races');
    }
  },

  showTodayRaces: () => async (dispatch) => {
    try {
      await showTodayRaces().then((res) => {
        toast.success(res.msg);
      })
    } catch (error) {
      toast.error('Error Showing races');
    }
  },

  automateRaceSelectionsSilver: () => async (dispatch) => {
    try {
      await automateRaceSelectionsSilver().then(res => {
        toast.success('RACES FOR THE SILVER PACKAGE HAS BEEN SAVED.');
      })

    } catch (error) {
      toast.error('ERROR AUTOMATING RACES FOR THE SILVER PACKAGE.')
    }
  },

  automateRaceSelectionsGold: () => async (dispatch) => {
    try {
      await automateRaceSelectionsGold().then(res => {
        toast.success('RACES FOR THE GOLD PACKAGE HAS BEEN SAVED.')
      })
    } catch (error) {
      toast.success('ERROR AUTOMATING RACES FOR THE GOLD PACKAGE.')
    }
  },

  generateRaceSelectionsPlatinum: () => async (dispatch) => {
    try {
      await generateRaceSelectionsPlatinum().then(res => {
        toast.success("PLATINUM RACES GENERATED.")
      })
    } catch (error) {
      toast.error("ERROR GENERATING PLATINUM RACES.")
    }
  },

  automateSilverResults: () => async (dispatch) => {
    try {
      await autoSilverResults().then(res => {
        toast.success('RESULT CRONES FOR THE SILVER RACES HAS BEEN TRIGERED.')
      })
    } catch (error) {
      toast.success('ERROR WITH RESULT CRONES FOR THE SILVER RACES.')
    }
  },

  automateGoldResults: () => async (dispatch) => {
    try {
      await autoGoldResults().then(res => {
        toast.success('RESULT CRONES FOR THE GOLD RACES HAS BEEN TRIGERED.')
      })
    } catch (error) {
      toast.success('ERROR WITH RESULT CRONES FOR THE GOLD RACES.')
    }
  },

};


export default actions;
