import { combineReducers } from "redux";

/* PAST THE POST */
import navigationReducer from "./navigation";
import analyticsReducer from "./analytics";
import userReducer from "./usersListReducers";
import usersActivityReducer from "./usersActivityReducers";
import userFormReducer from "./usersFormReducers";
import auth from "./auth";
import adminReducer from "./adminListReducers";
import adminFormReducer from "./adminFormReducers";
import selReducer from "./selectionReducer";
import apiReducer from "./apiReducer";
import racesReducer from "./racesAnalyticsReducer";
import venuesReducer from "./venuesReducer";
import emailReducer from "./emailReducer";
import onNowReducer from "./onNowReducer";
import homeTableFormReducersSP from "./homeTableFormReducersSP";
import homeTableListReducersSP from "./homeTableListReducersSP";
import messagesReducersSP from "./messagesReducersSP";
import advSelListReducers from "./advSelListReducers";
import advSelFormReducers from "./advSelFormReducers";

/* SMART PUNTING SYSTEM */

import raceReducerSP from "./racesListReducersSP";
import raceFormReducerSP from "./racesFormReducersSP";

import userReducerSP from "./usersListReducersSP";
import userFormReducerSP from "./usersFormReducersSP";
import userIpReducerSP from "./usersListIpReducerSP";

import leadFormReducerSP from "./leadsFormReducersSP";
import leadReducerSP from "./leadsListReducersSP";

const reducers = combineReducers({
  userIpReducerSP,
  leadFormReducerSP,
  leadReducerSP,
  navigationReducer,
  analyticsReducer,
  userReducer,
  userFormReducer,
  usersActivityReducer,
  auth,
  adminReducer,
  adminFormReducer,
  selReducer,
  apiReducer,
  racesReducer,
  venuesReducer,
  userReducerSP,
  raceReducerSP,
  userFormReducerSP,
  raceFormReducerSP,
  emailReducer,
  onNowReducer,
  homeTableFormReducersSP,
  homeTableListReducersSP,
  messagesReducersSP,
  advSelListReducers,
  advSelFormReducers,
});

export default reducers;
