import { GET_EMAIL_SUBJECT, GET_CONTACT_US_SUCCESS,
         GET_INBOX_START, GET_INBOX_SUCCESS, GET_INBOX_ERROR, DISABLE_BUTTONS,
         GET_STAT_MAILER_START, GET_STAT_MAILER_SUCCESS, GET_STAT_MAILER_ERROR,
        } from '../actions/emails';
  
  const initialState = {
    emailGeneration: '',
    emailMaintenance: '',
    emailPromo: '',
    fridayNight: '',
    contactUsEmails: [],
    statMailerLoading: false,
    statMailer: [],
    inbox: [],
    inboxLoading: false,
    buttonDisabled: true,
    };
  
  function emailReducer(state = initialState, action){
      switch (action.type) {
          case GET_EMAIL_SUBJECT:
              return Object.assign({}, state, {
                  emailGeneration: action.payload.emailGeneration,
                  emailMaintenance: action.payload.emailMaintenance,
                  emailPromo: action.payload.emailPromo,
                  fridayNight: action.payload.fridayNight,
                  buttonDisabled: action.payload.buttonDisabled
              });

          case DISABLE_BUTTONS:
              return Object.assign({}, state, {
                buttonDisabled: true,
              });

          case GET_CONTACT_US_SUCCESS:
              return Object.assign({}, state, {
                contactUsEmails: action.payload.contactUsEmails,
              });

          case GET_INBOX_START:
              return Object.assign({}, state, {
                inboxLoading: true,
              });
          case GET_INBOX_SUCCESS:
              return Object.assign({}, state, {
                inboxLoading: false,
                inbox: action.payload.inbox
              });
          case GET_INBOX_ERROR:
              return Object.assign({}, state, {
                inboxLoading: false,
                inbox: []
              });
              case GET_STAT_MAILER_START:
              return Object.assign({}, state, {
                statMailer: [],
                statMailerLoading: true,
              });
          case GET_STAT_MAILER_SUCCESS:
              return Object.assign({}, state, {
                statMailerLoading: false,
                statMailer: action.payload.statMailer
              });
          case GET_STAT_MAILER_ERROR:
              return Object.assign({}, state, {
                statMailerLoading: false,
                statMailer: []
              });
        default:
          return state;
      }
    }
    
  export default emailReducer;
  
  
  