import {
  RACESSP_LIST_FETCH_STARTED, RACESSP_LIST_FETCH_SUCCESS, RACESSP_LIST_FETCH_ERROR, RACESSP_FETCH_STARTED, RACESSP_FETCH_SUCCESS, RACESSP_FETCH_ERROR, RACESSP_LIST_DELETE_STARTED, RACESSP_LIST_DELETE_SUCCESS,
  RACESSP_LIST_DELETE_ERROR, RACESSP_LIST_OPEN_CONFIRM, RACESSP_LIST_CLOSE_CONFIRM, REMOVE_GOLD_STARTED, REMOVE_GOLD_SUCCESS, REMOVE_GOLD_ERROR
} from '../actionsSP/racesListActions';


const defaultState = {
  rows: [],
  loading: false,
};

function raceReducerSP(state = defaultState, action) {
  switch (action.type) {
    case RACESSP_LIST_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case RACESSP_LIST_FETCH_SUCCESS:
      console.log(action.payload)
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: action.payload.rows,
      });
    case RACESSP_LIST_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: [],
      });
    case RACESSP_FETCH_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case RACESSP_FETCH_SUCCESS:
      console.log(action.payload)
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: action.payload.rows,
      });
    case RACESSP_FETCH_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        rows: [],
      });

    case RACESSP_LIST_DELETE_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case RACESSP_LIST_DELETE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case RACESSP_LIST_DELETE_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case REMOVE_GOLD_STARTED:
      return Object.assign({}, state, {
        ...state,
        loading: true,
      });
    case REMOVE_GOLD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case REMOVE_GOLD_ERROR:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    case RACESSP_LIST_OPEN_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: true,
        idToDelete: action.payload.id
      });
    case RACESSP_LIST_CLOSE_CONFIRM:
      return Object.assign({}, state, {
        ...state,
        loading: false,
        modalOpen: false,
      });
    default:
      return state;
  }
}

export default raceReducerSP;