import {
  getAdmins, deleteAdmin, getStaffList,
  deleteStaff, addStaff,
} from '../../config/config';
import { toast } from 'react-toastify';

export const ADMINS_LIST_FETCH_STARTED = 'ADMINS_LIST_FETCH_STARTED';
export const ADMINS_LIST_FETCH_SUCCESS = 'ADMINS_LIST_FETCH_SUCCESS';
export const ADMINS_LIST_FETCH_ERROR = 'ADMINS_LIST_FETCH_ERROR';

export const ADMINS_LIST_DELETE_STARTED = 'ADMINS_LIST_DELETE_STARTED';
export const ADMINS_LIST_DELETE_SUCCESS = 'ADMINS_LIST_DELETE_SUCCESS';
export const ADMINS_LIST_DELETE_ERROR = 'ADMINS_LIST_DELETE_ERROR';

export const ADMINS_LIST_OPEN_CONFIRM = 'ADMINS_LIST_OPEN_CONFIRM';
export const ADMINS_LIST_CLOSE_CONFIRM = 'ADMINS_LIST_CLOSE_CONFIRM';

export const STAFF_LIST_FETCH_STARTED = 'STAFF_LIST_FETCH_STARTED';
export const STAFF_LIST_FETCH_SUCCESS = 'STAFF_LIST_FETCH_SUCCESS';
export const STAFF_LIST_FETCH_ERROR = 'STAFF_LIST_FETCH_ERROR';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: (filter, keepPagination = false) => async (dispatch) => {
    try {
      dispatch({
        type: ADMINS_LIST_FETCH_STARTED,
        payload: { filter, keepPagination },
      });

      const response = await getAdmins()
if (response.status === 200){
      dispatch({
        type: ADMINS_LIST_FETCH_SUCCESS,
        payload: {
          rows: response.data,
        },
      });
    } else {
      toast.error(selectErrorMessage("error bajet"))
    }
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: ADMINS_LIST_FETCH_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: ADMINS_LIST_DELETE_STARTED,
      });

      await deleteAdmin({ 'admin_id': id }) /*add later on to handle if their is an error*/

      dispatch({
        type: ADMINS_LIST_DELETE_SUCCESS,
      });

      const response = await getAdmins()
      dispatch({
        type: ADMINS_LIST_FETCH_SUCCESS,
        payload: {
          rows: response,
        },
      });
      toast.error('Admin Account removed successfully');

    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: ADMINS_LIST_DELETE_ERROR,
      });
    }
  },

  doOpenConfirm: (id) => async (dispatch) => {
    dispatch({
      type: ADMINS_LIST_OPEN_CONFIRM,
      payload: {
        id: id
      },
    });
  },

  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: ADMINS_LIST_CLOSE_CONFIRM,
    });
  },

  staffControl: () => async (dispatch) => {
    try {
      dispatch({
        type: STAFF_LIST_FETCH_STARTED,
      });

      const response = await getStaffList()

      dispatch({
        type: STAFF_LIST_FETCH_SUCCESS,
        payload: {
          staff: response,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: STAFF_LIST_FETCH_ERROR,
      });
    }
  },
  // getClient: () => async (dispatch) => {
  //   try {
  //     dispatch({
  //       type: STAFF_LIST_FETCH_STARTED,
  //     });

  //     const response = await getClients()

  //     dispatch({
  //       type: STAFF_LIST_FETCH_SUCCESS,
  //       payload: {
  //         dob: response,
  //       },
  //     });
  //   } catch (error) {
  //     toast.error(selectErrorMessage(error))

  //     dispatch({
  //       type: STAFF_LIST_FETCH_ERROR,
  //     });
  //   }
  // },



  deleteStaff: (id) => async (dispatch) => {
    try {
      await deleteStaff({ 'staff_id': id }).then(res => {
        toast.success('Staff removed successfully');
        dispatch(actions.staffControl())
      })


    } catch (error) {
      toast.error(selectErrorMessage(error))
      dispatch({
        type: STAFF_LIST_FETCH_ERROR,
      });
    }
  },

  doAddStaff: (data) => async (dispatch) => {
    try {
      await addStaff(data).then(res => {
        toast.success('NEW STAFF MEMBER ADDED SUCCESSFULLY')
      })
    } catch (error) {
      toast.error('AN ERROR OCCURED WHILE ADDING NEW MEMEBR PLEASE RECHECK THE INFORMATIONS')
    }
  },
};


export default actions;
