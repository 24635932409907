import { getMessagesInfo, deleteMessages } from '../../config/configSP';
import { toast } from 'react-toastify';
export const MESSAGES_LIST_FETCH_STARTED = 'MESSAGES_LIST_FETCH_STARTED';
export const MESSAGES_LIST_FETCH_SUCCESS = 'MESSAGES_LIST_FETCH_SUCCESS';
export const MESSAGES_LIST_FETCH_ERROR = 'MESSAGES_LIST_FETCH_ERROR';
export const MESSAGES_LIST_DELETE_STARTED = 'MESSAGES_LIST_DELETE_STARTED';
export const MESSAGES_LIST_DELETE_SUCCESS = 'MESSAGES_LIST_DELETE_SUCCESS';
export const MESSAGES_LIST_DELETE_ERROR = 'MESSAGES_LIST_DELETE_ERROR';


function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: (filter, keepPagination = false) => async (dispatch) => {
    try {
      dispatch({
        type: MESSAGES_LIST_FETCH_STARTED,
        payload: { filter, keepPagination },
      });
      const response = await getMessagesInfo();
      // console.log(response)
      // if(response.message === "success"){
      dispatch({
        type: MESSAGES_LIST_FETCH_SUCCESS,
        payload: {
          rows: response.data,
        },
      });
    // } else {
    //   toast.error(selectErrorMessage("error getting messages"))
    // }
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: MESSAGES_LIST_FETCH_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: MESSAGES_LIST_DELETE_STARTED,
      });

      await deleteMessages({ 'id': id })

      dispatch({
        type: MESSAGES_LIST_DELETE_SUCCESS,
      });

      const response = await getMessagesInfo();
      if(response.status === 200){
      dispatch({
        type: MESSAGES_LIST_FETCH_SUCCESS,
        payload: {
          rows: response.data,
        },
      });
    }
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: MESSAGES_LIST_DELETE_ERROR,
      });
    }
  },
};


export default actions;
