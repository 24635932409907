import { connect } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  //InputGroupAddon,
  //InputGroupText,
  //InputGroup,
  //Input,
  //UncontrolledAlert,
  Dropdown,
  //Collapse,
  DropdownToggle,
  DropdownMenu,
  //DropdownItem,
  //Badge,
  ButtonGroup,
  Button,
  //Form,
  //FormGroup,
} from "reactstrap";
//import Notifications from '../Notifications';
import { logoutUser } from "../../redux/actions/auth";
import { logoutPTPUser } from "../../redux/actions/auth";
import {
  openSidebar,
  closeSidebar,
  changeSidebarPosition,
  changeSidebarVisibility,
} from "../../redux/actions/navigation";
import { withRouter } from "react-router-dom";
import sender1 from "../../styles/images/1.png";
import Clock from "react-live-clock";

import s from "./Header.module.scss";

class Header extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebarPosition: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      supportOpen: false,
      settingsOpen: false,
      searchFocused: false,
      searchOpen: false,
      notificationsOpen: false,
    };
  }

  toggleNotifications = () => {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen,
    });
  };

  onDismiss = () => {
    this.setState({ visible: false });
  };

  doLogout = () => {
    let user = JSON.parse(this.props.currentUser);
    {user.role !== 10 ?
    this.props.dispatch(logoutUser(this.props.history)) : this.props.dispatch(logoutPTPUser(this.props.history))
  }
};

  toggleSupportDropdown = () => {
    this.setState({
      supportOpen: !this.state.supportOpen,
    });
  };

  toggleSettingsDropdown = () => {
    this.setState({
      settingsOpen: !this.state.settingsOpen,
    });
  };

  toggleAccountDropdown = () => {
    this.setState({
      accountOpen: !this.state.accountOpen,
    });
  };

  toggleSearchOpen = () => {
    this.setState({
      searchOpen: !this.state.searchOpen,
    });
  };

  toggleSidebar = () => {
    this.props.isSidebarOpened
      ? this.props.dispatch(closeSidebar())
      : this.props.dispatch(openSidebar());
  };

  moveSidebar = (position) => {
    this.props.dispatch(changeSidebarPosition(position));
  };

  toggleVisibilitySidebar = (visibility) => {
    this.props.dispatch(changeSidebarVisibility(visibility));
  };

  render() {
    const user = JSON.parse(this.props.currentUser);
    //const avatar = user && user.avatar && user.avatar.length && user.avatar[0].publicUrl;
    //const firstUserLetter = user && (user.firstName|| user.email)[0].toUpperCase();
    return (
      <Navbar className={`d-print-none ${s.root}`}>
        <Clock
          format={"YYYY-MM-DD HH:mm:ss"}
          ticking={true}
          timezone={"Australia/Sydney"}
          className={`${s.clock} text-white`}
        />

        {/*<UncontrolledAlert className={`${s.alert} mr-3 d-lg-down-none`}>
          <i className="fa fa-info-circle mr-1" /> Check Side Bar <button className="btn-link" onClick={() => this.setState({ settingsOpen: true })}>settings</button> on
          the right!
        </UncontrolledAlert>*/}

        {/*<Collapse className={`${s.searchCollapse} ml-lg-0 mr-md-3`} isOpen={this.state.searchOpen}>
          <InputGroup className={`${s.navbarForm} ${this.state.searchFocused ? s.navbarFormFocused : ''}`}>
            <InputGroupAddon addonType="prepend" className={s.inputAddon}><InputGroupText><i className="fa fa-search" /></InputGroupText></InputGroupAddon>
            <Input
              id="search-input-2" placeholder="Search..." className="input-transparent"
              onFocus={() => this.setState({ searchFocused: true })}
              onBlur={() => this.setState({ searchFocused: false })}
            />
          </InputGroup>
        </Collapse>*/}

        {/*<Form className="d-md-down-none mr-3 ml-3" inline>
          <FormGroup>
            <InputGroup className="input-group-no-border">
              <InputGroupAddon addonType="prepend">
                <InputGroupText><i className="fa fa-search text-white" /></InputGroupText>
              </InputGroupAddon>
              <Input id="search-input" className="input-transparent" placeholder="Search" />
            </InputGroup>
          </FormGroup>
        </Form>*/}

        <Nav className="ml-md-0">
          <Dropdown
            nav
            isOpen={this.state.notificationsOpen}
            toggle={this.toggleNotifications}
            id="basic-nav-dropdown"
            className={`${s.notificationsMenu}`}>
            <DropdownToggle nav style={{ color: "#f4f4f5", padding: 0 }}>
              <span
                className={`${s.avatar} rounded-circle thumb-sm float-left mr-2`}>
                <img
                  src={sender1}
                  onError={(e) => (e.target.src = sender1)}
                  alt="..."
                  title={user && user.email}
                />
              </span>
              <span className={`small d-sm-down-none`}> {user.name}</span>
              {/*<Badge className={s.badge} color="primary">2</Badge>
              <i className="fa fa-angle-down mr-1" />*/}
            </DropdownToggle>

            {/*<DropdownMenu right className={`${s.notificationsWrapper} py-0 animated animated-fast fadeInUp`}>
              <Notifications />
            </DropdownMenu>*/}
          </Dropdown>

          {/* this search button appears only for small screens */}
          {/*<NavItem className="d-lg-none d-md-block">
            <NavLink onClick={this.toggleSearchOpen} className={s.navItem} href="#">
              <i className="glyphicon glyphicon-search text-white" />
            </NavLink>
          </NavItem>*/}

          <NavItem className={`${s.divider} text-white`} />

          <Dropdown
            nav
            isOpen={this.state.settingsOpen}
            toggle={this.toggleSettingsDropdown}>
            <DropdownToggle nav className={`${s.navItem} text-white`}>
              <i className="glyphicon glyphicon-cog" />
            </DropdownToggle>
            <DropdownMenu className={`${s.dropdownMenu} ${s.settings}`}>
              <h6>Sidebar on the</h6>
              <ButtonGroup size="sm">
                <Button
                  color="dark"
                  onClick={() => this.moveSidebar("left")}
                  className={
                    this.props.sidebarPosition === "left" ? "active" : ""
                  }>
                  Left
                </Button>
                <Button
                  color="dark"
                  onClick={() => this.moveSidebar("right")}
                  className={
                    this.props.sidebarPosition === "right" ? "active" : ""
                  }>
                  Right
                </Button>
              </ButtonGroup>
              <h6 className="mt-2">Sidebar</h6>
              <ButtonGroup size="sm">
                <Button
                  color="dark"
                  onClick={() => this.toggleVisibilitySidebar("show")}
                  className={
                    this.props.sidebarVisibility === "show" ? "active" : ""
                  }>
                  Show
                </Button>
                <Button
                  color="dark"
                  onClick={() => this.toggleVisibilitySidebar("hide")}
                  className={
                    this.props.sidebarVisibility === "hide" ? "active" : ""
                  }>
                  Hide
                </Button>
              </ButtonGroup>
            </DropdownMenu>
          </Dropdown>

          {/*<Dropdown nav isOpen={this.state.supportOpen} toggle={this.toggleSupportDropdown}>
            <DropdownToggle nav className={`${s.navItem} text-white`}>
              <i className="glyphicon glyphicon-globe" />
              <span className={s.count}>3</span>
            </DropdownToggle>
            <DropdownMenu right className={`${s.dropdownMenu} ${s.support}`}>
              <DropdownItem>
                <Badge color="danger"><i className="fa fa-bell-o" /></Badge>
                <div className={s.details}>
                  notification 1
                </div>
              </DropdownItem>
              <DropdownItem>
                <Badge color="warning"><i className="fa fa-question-circle" /></Badge>
                <div className={s.details}>
                  notification 2
                </div>
              </DropdownItem>
              <DropdownItem>
                <Badge color="success"><i className="fa fa-info-circle" /></Badge>
                <div className={s.details}>
                  notification 3
                </div>
              </DropdownItem>
              <DropdownItem>
               <a href="#" className="text-white">
                  See all Notifications<i className="fa fa-arrow-right" />
                </a>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>*/}

          <NavItem>
            <NavLink
              onClick={this.doLogout}
              className={`${s.navItem} text-white`}
              href="#">
              <i className="glyphicon glyphicon-off" />
            </NavLink>
          </NavItem>

          <NavItem className="d-md-none">
            <NavLink
              onClick={this.toggleSidebar}
              className={`${s.navItem} text-white`}
              href="#">
              <i className="fa fa-bars" />
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => ({
  isSidebarOpened: state.navigationReducer.sidebarOpened,
  sidebarVisibility: state.navigationReducer.sidebarVisibility,
  sidebarPosition: state.navigationReducer.sidebarPosition,
  currentUser: state.auth.currentUser,
});

export default withRouter(connect(mapStateToProps)(Header));
