import {
  LOGIN_REQUEST, LOGIN_SUCCESS, AUTH_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS, RESET_REQUEST, RESET_SUCCESS,
  PASSWORD_RESET_EMAIL_REQUEST, PASSWORD_RESET_EMAIL_SUCCESS, AUTH_INIT_SUCCESS, AUTH_INIT_ERROR, AUTH_USERS_INIT_SUCCESS , AUTH_USERS_INIT_ERROR , LOGIN_USERS_REQUEST , LOGIN_USERS_SUCCESS , AUTH_USERS_FAILURE ,
  REGISTER_REQUEST, REGISTER_SUCCESS
} from '../actions/auth';

const initialState = {
    isFetching: false,
    errorMessage: '',
    currentUser: null,
    loadingInit: true,

    isFetchingPtp: false,
    errorMessagePtp: '',
    loadingInitPtp: true,
  };

function auth(state = initialState, action){
    switch (action.type) {
        case LOGIN_REQUEST:
        case LOGOUT_REQUEST:
        case RESET_REQUEST:
        case PASSWORD_RESET_EMAIL_REQUEST:
        case REGISTER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errorMessage: '',
                isFetchingPtp: true,
                errorMessagePtp: '',
            });
        case LOGIN_USERS_REQUEST:
                return Object.assign({}, state, {
                    isFetchingPtp: true,
                    errorMessagePtp: '',
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
            });
        case LOGIN_USERS_SUCCESS:
            return Object.assign({}, state, {
                isFetchingPtp: false,
            });
        case RESET_SUCCESS:
        case PASSWORD_RESET_EMAIL_SUCCESS:
        case REGISTER_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: '',
            });
        case AUTH_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errorMessage: action.payload,
            });
        case AUTH_USERS_FAILURE:
            return Object.assign({}, state, {
                isFetchingPtp: false,
                errorMessagePtp: action.payload,
            });
        case AUTH_INIT_SUCCESS:
            return Object.assign({}, state, {
                currentUser: action.payload.currentUser,
                loadingInit: false,
            });
        case AUTH_USERS_INIT_SUCCESS:
            return Object.assign({}, state, {
                currentUser: action.payload.currentUser,
                loadingInitPtp: false,
            });
        case AUTH_INIT_ERROR:
            return Object.assign({}, state, {
                currentUser: null,
                loadingInit: false,
            });
        case AUTH_USERS_INIT_ERROR:
            return Object.assign({}, state, {
                currentUser: null,
                loadingInitPtp: false,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                currentUser: null,
                loadingInit: false,
                isFetching: false,
                loadingInitPtp: false,
                isFetchingPtp: false,
            });
      default:
        return state;
    }
  }
  
export default auth;


