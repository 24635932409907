import { saveAdvancedSelections, deleteAdvancedSelections, getAdvancedSelections } from '../../config/config';
import { rmvABND } from '../../config/configSP';
import { toast } from 'react-toastify';

export const ADVSEL_LIST_FETCH_STARTED    = 'ADVSEL_LIST_FETCH_STARTED';
export const ADVSEL_LIST_FETCH_SUCCESS    = 'ADVSEL_LIST_FETCH_SUCCESS';
export const ADVSEL_LIST_FETCH_ERROR      = 'ADVSEL_LIST_FETCH_ERROR';
export const ADVSEL_LIST_DELETE_STARTED  = 'ADVSEL_LIST_DELETE_STARTED';
export const ADVSEL_LIST_DELETE_SUCCESS  = 'ADVSEL_LIST_DELETE_SUCCESS';
export const ADVSEL_LIST_DELETE_ERROR    = 'ADVSEL_LIST_DELETE_ERROR';
export const ADVSEL_LIST_OPEN_CONFIRM    = 'ADVSEL_LIST_OPEN_CONFIRM';
export const ADVSEL_LIST_CLOSE_CONFIRM   = 'ADVSEL_LIST_CLOSE_CONFIRM';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

const actions = {

  doFetch: (date, filter, keepPagination = false) => async (dispatch) => {
    try {
      dispatch({
        type: ADVSEL_LIST_FETCH_STARTED,
        payload: { filter, keepPagination },
      });
      const response = await getAdvancedSelections(date?.startingDate, date?.endDate);
      // console.log(response.data);

      dispatch({
        type: ADVSEL_LIST_FETCH_SUCCESS,
        payload: {
          rows: response.data,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: ADVSEL_LIST_FETCH_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: ADVSEL_LIST_DELETE_STARTED,
      });

      

      await deleteAdvancedSelections ({'id': id})

      dispatch({
        type: ADVSEL_LIST_DELETE_SUCCESS,
      });

    } catch (error) {
      toast.error(selectErrorMessage(error))

      dispatch({
        type: ADVSEL_LIST_DELETE_ERROR,
      });
    }
  },

  doOpenConfirm: (id) => async (dispatch) => {
    dispatch({
      type: ADVSEL_LIST_OPEN_CONFIRM,
      payload: {
        id: id
      },
    });
  },

  removeABND: () => async (dispatch) => {
    try{
      await rmvABND().then(res =>{
        toast.success('ABND RACES REMOVED');
      })
    } catch(error) {
      console.log(error);
      toast.error(error)
    }
  },

  doCloseConfirm: () => async (dispatch) => {
    dispatch({
      type: ADVSEL_LIST_CLOSE_CONFIRM,
    });
  },
};



export default actions;
