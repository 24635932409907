import axios from "axios";

const hostApi =
  process.env.NODE_ENV === "development" ? "http://localhost" : ``;
const portApi = process.env.NODE_ENV === "development" ? 3100 : null;

const baseURLApi = `${hostApi}${portApi ? `:${portApi}` : ``}/api`;
const baseURLApiLive = `${hostApi}${portApi ? `:${portApi}` : ``}/liveApi`;
const bookieLive = `${hostApi}${portApi ? `:${portApi}` : ``}/bookie`;
const ptpCrone = `${hostApi}${portApi ? `:${portApi}` : ``}/ptpCrone`;
const config = {
  headers: { Authorization: `PTPADMIN c91fe46c-7033-4525-87cc-08f81e3f4356` },
};

async function getUsersAnalytics(data) {
  const url = `${baseURLApi}/usersAnalytics`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function getMarketingAnalytics(data) {
  const url = `${baseURLApi}/marketingAnalytics`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getUsers() {
  const url = `${baseURLApi}/users`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function getUsersDate(date) {
  const url = `${baseURLApi}/getUsersDate`;
  return await axios.post(url, date, config).then((response) => response.data);
}

async function getUser(id) {
  const url = `${baseURLApi}/user`;
  return await axios.post(url, id, config).then((response) => response.data);
}

async function createUser(data) {
  const url = `${baseURLApi}/createUser`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function updateUser(data) {
  const url = `${baseURLApi}/updateUser`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function deleteUser(data) {
  const url = `${baseURLApi}/deleteUser`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function removeProTips(data) {
  const url = `${baseURLApi}/removeProTips`;
  return await axios.post(url, data, config).then((response) => response);
}

async function getUsersActivity(id) {
  const url = `${baseURLApi}/usersActivity`;
  return await axios
    .post(url, id, config)
    .then((response) => response.data.data);
}

async function getUsersFrequency(date) {
  const url = `${baseURLApi}/usersFrequency`;
  return await axios
    .post(url, date, config)
    .then((response) => response.data.data);
}

async function doFetchOnline(date) {
  console.log(date);
  const url = `${baseURLApi}/doFetchOnline`;
  return await axios
    .post(url, date, config)
    .then((response) => response.data.data);
}

async function lastSignUp() {
  const url = `${baseURLApi}/lastSignUp`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function lastSignIn(data) {
  const url = `${baseURLApi}/lastSignIn`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function emailPromoAnalytics() {
  const url = `${baseURLApi}/emailAnalytics`;
  return await axios.get(url, config).then((response) => response.data);
}

async function getOnlinePTP(data) {
  const url = `${baseURLApi}/getOnlinePTP`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getVisitorOrClientOnline() {
  const url = `${baseURLApi}/getVisitorOrClientOnline`;
  return await axios.get(url, config).then((response) => response?.data);
}

async function getVisitorsData(data) {
  const url = `${baseURLApi}/getVisitorsData`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function doFetchVisitor(data) {
  const url = `${baseURLApi}/doFetchVisitor`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getDailyFrequencyUsers() {
  const url = `${baseURLApi}/getDailyFrequencyUsers`;
  return await axios
    .get(url, config)
    .then((response) => response?.data?.data)
    .catch((error) => error);
}

async function getHorsesProfile() {
  const url = `${baseURLApi}/getHorsesProfile`;
  return await axios
    .get(url, config)
    .then(
      (response) =>
        // console.log(response?.data.data)
        response?.data
    )
    .catch((error) => error);
}

/* ADMINS */
async function getAdmin(id) {
  const url = `${baseURLApi}/findAdmin`;
  return await axios.post(url, id, config).then((response) => response.data);
}

async function getAdmins() {
  const url = `${baseURLApi}/admins`;
  return await axios.get(url, config).then((response) => response.data);
}

async function getStaffList() {
  const url = `${baseURLApi}/getStaff`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function deleteAdmin(data) {
  const url = `${baseURLApi}/deleteAdmin`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function createAdmin(data) {
  const url = `${baseURLApi}/createAdmin`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function updateAdmin(data) {
  const url = `${baseURLApi}/updateAdmin`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function loginAdmin(token) {
  const url = `${baseURLApi}/loginAdmin`;
  return await axios.post(url, token, config).then((response) => response.data);
}

async function loginAdminForPTPUser(token) {
  console.log(token)
  const url = `${baseURLApi}/loginAdminForPTPUser`;
  return await axios.post(url, token, config).then((response) => response);
}

async function deleteStaff(data) {
  const url = `${baseURLApi}/deleteStaff`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function addStaff(data) {
  const url = `${baseURLApi}/addStaff`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getSubPlan() {
  const url = `${baseURLApi}/getSubscriptionPlan`;
  return await axios.get(url, config).then((response) => response.data);
}

async function generateSiteMap() {
  const url = `${baseURLApi}/generateSiteMapXML`;
  return await axios.get(url, config).then((response) => response.data);
}

async function cleanLocalhosts() {
  const url = `${baseURLApi}/cleanLocalhosts`;
  return await axios.get(url, config).then((response) => response.data);
}

async function generateAIJsonReport() {
  const url = `${baseURLApi}/generateAIJson`;
  return await axios.get(url, config).then((response) => response.data);
}

/* API */

// Get date of birth of Client
async function getClient() {
  const url = `${baseURLApiLive}/getClient`;
  return await axios.get(url, config).then((response) => response.data);
}

async function sendGenerationEmailAll() {
  const url = `${baseURLApiLive}/sendGenerationEmailAll`;
  return await axios.get(url, config).then((response) => response.data);
}

async function sendMaintenanceEmailsAll() {
  const url = `${baseURLApiLive}/sendMaintenanceEmailsAll`;
  return await axios.get(url, config).then((response) => response.data);
}

async function sendPromoEmailAll() {
  const url = `${baseURLApiLive}/sendPromoEmailAll`;
  return await axios.get(url, config).then((response) => response.data);
}

async function enableEmailButtons() {
  const url = `${baseURLApiLive}/enableButtons`;
  return await axios.get(url, config).then((response) => response.data);
}

async function sendFridayNightEmail() {
  const url = `${baseURLApiLive}/sendFridayNight`;
  return await axios.get(url, config).then((response) => response.data);
}

async function getEmailsSubjects() {
  const url = `${baseURLApiLive}/getEmailsSubjects`;
  return await axios.get(url, config).then((response) => response.data);
}

async function getContactUsEmail() {
  const url = `${baseURLApiLive}/getContactUsEmail`;
  return await axios.get(url, config).then((response) => response.data);
}

async function getInboxEmails() {
  const url = `${baseURLApiLive}/getInboxEmails`;
  return await axios.get(url, config).then((response) => response.data);
}

async function editEmailsSubjects(data) {
  const url = `${baseURLApiLive}/editEmailsSubjects`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function markEmailsRead(data) {
  const url = `${baseURLApiLive}/markEmailsRead`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function sendEmailContactUs(data) {
  const url = `${baseURLApiLive}/sendEmailContactUs`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function sendVerificationEmail(email) {
  const url = `${baseURLApiLive}/sendVerificationEmail`;
  return await axios.post(url, email, config).then((response) => response.data);
}

async function sendResetPassEmail(email) {
  const url = `${baseURLApiLive}/sendResetPassword`;
  return await axios.post(url, email, config).then((response) => response.data);
}

async function getUnibetDB() {
  const url = `${baseURLApiLive}/getUnibetRacesDB`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function updateUnibetDB(data) {
  const url = `${baseURLApiLive}/UpdateUnibetIDDB`;
  return await axios.post(url, data, config).then((response) => response);
}

async function fixBookieOrderDB(date) {
  const url = `${baseURLApiLive}/fixBookieOrder`;
  return await axios.post(url, date, config).then((response) => response);
}

async function generateNADB(date) {
  const url = `${baseURLApiLive}/generateNA`;
  return await axios.post(url, date, config).then((response) => response);
}

async function getTopTracks(months) {
  const url = `${baseURLApiLive}/getTopTracks`;
  return await axios
    .post(url, months, config)
    .then((response) => response.data.data);
}

async function getAllVenues() {
  const url = `${baseURLApiLive}/getAllVenues`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function deleteVenueDB(id) {
  const url = `${baseURLApiLive}/deleteVenue`;
  return await axios.post(url, id, config).then((response) => response);
}

async function updateVenueDB(data) {
  const url = `${baseURLApiLive}/updateVenue`;
  return await axios.post(url, data, config).then((response) => response);
}

async function createVenueDB(data) {
  const url = `${baseURLApiLive}/createVenue`;
  return await axios.post(url, data, config).then((response) => response);
}

async function clicksAffiliates(data) {
  const url = `${baseURLApiLive}/analyticsAffiliates`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function getSelectedVenue(passedDate) {
  const url = `${baseURLApiLive}/getSelectedVenue`;
  return await axios
    .post(url, passedDate, config)
    .then((response) => response.data.data);
}

async function getvenueInfo(data) {
  const url = `${baseURLApiLive}/getVenueInfo`;
  return await axios.post(url, data, config).then((response) => response);
}

async function updateTrackCond(data) {
  const url = `${baseURLApiLive}/updateTrackCondition`;
  return await axios.post(url, data, config).then((response) => response);
}

async function updateRaceInfo(data) {
  const url = `${baseURLApiLive}/updateRaceInformation`;
  return await axios.post(url, data, config).then((response) => response);
}

async function updateRaceDetails(data) {
  const url = `${baseURLApiLive}/updateRaceDetails`;
  return await axios.post(url, data, config).then((response) => response);
}

async function doFindAllInfo(id) {
  const url = `${baseURLApiLive}/findAllRaceInfo`;
  return await axios
    .post(url, id, config)
    .then((response) => response.data.data);
}

async function updateHorseInfo(data) {
  const url = `${baseURLApiLive}/updateHorseInfo`;
  return await axios.post(url, data, config).then((response) => response);
}

async function getFrequentUsers(checked) {
  const url = `${baseURLApiLive}/getFrequentUsers`;
  return await axios
    .post(url, checked, config)
    .then((response) => response.data.data);
}

async function getResultsUserPref(data) {
  const url = `${baseURLApiLive}/getResultsBasedOnUser`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function getResultsUserRodPref(data) {
  const url = `${baseURLApiLive}/getResultsBasedOnUserRod`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function getTracksBasedOnDate(data) {
  const url = `${baseURLApiLive}/getTracksBasedOnDate`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function getHJT(data) {
  const url = `${baseURLApiLive}/getHJSbasedOnDate`;
  return await axios
    .post(url, data, config)
    .then((response) => response.data.data);
}

async function getVenuesState() {
  const url = `${baseURLApiLive}/getVenuesState`;
  return await axios.get(url, config).then((response) => response.data.data);
}

async function mailChimpCreateAudience(data) {
  const url = `${baseURLApiLive}/mailChimpAudience`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getSelectedOnNow() {
  const url = `${baseURLApiLive}/getSelectedOnNow`;
  return await axios.get(url, config).then((response) => response.data);
}

async function updateOnNowRaceID(data) {
  const url = `${baseURLApiLive}/updateOnNowRaceID`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getVenueDetails(data) {
  const url = `${baseURLApiLive}/getVenueDetails`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function updateVenueDetails(data) {
  const url = `${baseURLApiLive}/updateVenueDetails`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function query(data) {
  const url = `${baseURLApiLive}/query`;
  return await axios.post(url, data, config).then((response) => response.data);
}

//UPDATE RACE STATUS OPEN/CLOSED
async function updateRaceStatus(data) {
  const url = `${baseURLApiLive}/updateRaceStatus`;
  return await axios.post(url, data, config).then((response) => response);
}

/* BOOKIE API */

async function UnibetCheckEmptyId() {
  const url = `${bookieLive}/Unibet/getUBVenueID`;
  return await axios.get(url, config).then((response) => response);
}

async function updateRacesRatings(id) {
  const url = `${bookieLive}/Unibet/updateRacesRatings`;
  return await axios.post(url, id , config).then((response) => response);
}

async function checkSBprices() {
  const url = `${bookieLive}/SportsBetting/checkPrices`;
  return await axios.get(url, config).then((response) => response);
}

async function InsertOrUpdateRaces() {
  const url = `${ptpCrone}/Unibet/getUBVenues`;
  return await axios.get(url, config).then((response) => response);
}

async function InsertOrUpdateHorses() {
  const url = `${ptpCrone}/Unibet/getUBRaces`;
  return await axios.get(url, config).then((response) => response);
}

async function InsertLBID() {
  const url = `${ptpCrone}/LB/getLBID`;
  return await axios.get(url, config).then((response) => response);
}

async function InsertSBID() {
  const url = `${ptpCrone}/SB/getSBID`;
  return await axios.get(url, config).then((response) => response);
}

async function UpdateTimeAndTrackCoditionToday() {
  const url = `${ptpCrone}/UB/TrackCondTime`;
  return await axios.get(url, config).then((response) => response);
}

async function LBSBDetails() {
  const url = `${ptpCrone}/LBSB/updateLBSB`;
  return await axios.get(url, config).then((response) => response);
}

async function SBODDS() {
  const url = `${ptpCrone}/SB/SBODDS`;
  return await axios.get(url, config).then((response) => response);
}

async function SendTipOfTheDay() {
  const url = `${ptpCrone}/tipOfTheDay`;
  return await axios.get(url, config).then((response) => response);
}

async function GetSBDetails() {
  const url = `${ptpCrone}/SB/GETIDS`;
  return await axios.get(url, config).then((response) => response.data);
}

async function UpdateNames() {
  const url = `${ptpCrone}/Unibet/getUBNames`;
  return await axios.get(url, config).then((response) => response);
}

async function UpdateHorsesIDs() {
  const url = `${ptpCrone}/DB/UpdateHorseIds`;
  return await axios.get(url, config).then((response) => response);
}

async function updateVenueState(data) {
  const url = `${baseURLApiLive}/UpdateVenueState`;
  return await axios.post(url, data, config).then((response) => response);
}

async function AssignThirdUpHorseProfile() {
  const url = `${ptpCrone}/AssignThirdUp`;
  return await axios.get(url, config).then((response) => response);
}

/* SELECTIONS */
/**
 *
 * @param {Race info} data
 */

function upload(data) {
  const url = `${baseURLApi}/selections/upload`;
  return axios.post(url, data, config).then((response) => response);
}

function uploadRace() {
  const url = `${baseURLApi}/selections/uploadRace`;
  //return axios.post(url, data, config).then(response => response);
  return url;
}

/**
 * 
 * @returns emptyAAPTables = (event) => {
    event.preventDefault();
    const { dispatch } = this.props;
    dispatch(
      emptyAAP().then((res) => {
        alert(res.data);
      })
    );
  };
 */

function triggerPaceCalc() {
  const url = `${baseURLApi}/selections/getHorsespace`;
  return axios.get(url, config).then((response) => response);
}

function triggerpacecalcTomorrow() {
  const url = `${baseURLApi}/selections/getHorsespacetomorrow`;
  return axios.get(url, config).then((response) => response);
}

function emptyAAP() {
  const url = `${baseURLApi}/selections/emptyAAPTables`;
  return axios.get(url, config).then((response) => response);
}

function checkProgress(data) {
  const url = `${baseURLApi}/selections/checkProgress`;
  return axios.post(url, data, config).then((response) => response);
}

function retrieveLoadedVenues() {
  const url = `${baseURLApi}/selections/retrieveldvenues`;
  return axios.get(url, config).then((response) => response.data);
}

const loadBarriersPTP2XLS = async () => {
  const url = `${baseURLApi}/selectionsfs/loadBarriersPTP2XLS`;
  return await axios.get(url, config).then((response) => response.data);
};

const loadRailPosPTP2XLS = async () => {
  const url = `${baseURLApi}/selectionsfs/loadRailPosPTP2XLS`;
  return await axios.get(url, config).then((response) => response.data);
};

const loadStraightsPTP2XLS = async () => {
  const url = `${baseURLApi}/selectionsfs/loadStraightsPTP2XLS`;
  return await axios.get(url, config).then((response) => response.data);
};

function getRaces(date, trackCode) {
  const url = `${baseURLApi}/selections/races`;
  let data = { date: date, trackCode: trackCode };
  return axios.post(url, data, config).then((response) => response.data);
}

function getFormings(data) {
  const url = `${baseURLApi}/selections/formings`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getHorsePoints(data) {
  const url = `${baseURLApi}/selections/horsePoints`;
  return axios.post(url, data, config).then((response) => response.data);
}

function formingsForRaces(data) {
  const url = `${baseURLApi}/selections/formingallraces`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getPositions() {
  const url = `${baseURLApi}/selections/positions`;
  return axios.get(url, config).then((response) => response.data);
}

function loadRaceForm(item) {
  const url = `${baseURLApi}/selections/loadRaceForm`;
  return axios.post(url, item, config).then((response) => response.data);
}

async function getDistances() {
  const url = `${baseURLApi}/selections/distances`;
  return await axios.get(url, config).then((response) => response.data);
}

function jockeyPremierShip() {
  const url = `${baseURLApi}/selections/jockeypremiership`;
  return axios.get(url, config).then((response) => response.data);
}

function assignHorseScratching(item) {
  const url = `${baseURLApi}/selections/assignhorsescratching`;
  return axios.post(url, item, config).then((response) => response.data);
}

function loadHorseUnscratched(item) {
  const url = `${baseURLApi}/selections/loadHorseUnscratched`;
  return axios.post(url, item, config).then((response) => response.data);
}

function savePoints(data) {
  const url = `${baseURLApi}/selections/savepoints`;
  return axios.post(url, data, config).then((response) => response.data);
}

function deletePoints(data) {
  const url = `${baseURLApi}/selections/deletepoints`;
  return axios.post(url, data, config).then((response) => response.data);
}

function best4HPer(race) {
  const url = `${baseURLApi}/selections/best4hper`;
  return axios.post(url, race, config).then((response) => response.data);
}

function assignThirdUp(race) {
  const url = `${baseURLApi}/selections/assignThirdUp`;
  return axios.post(url, race, config).then((response) => response.data);
}

function showRaces(race) {
  const url = `${baseURLApi}/selections/showraces`;
  return axios.post(url, race, config).then((response) => response);
}

const generateFreeTips = (race) => {
  const url = `${baseURLApi}/selections/generatefreetips`;
  return axios.post(url, race, config).then((response) => response.data);
}

const updateDevServer = async () => {
  const url = `${baseURLApi}/selections/updatedevselections`;
  return axios.get(url, config).then((response) => response.data);
}

const pushtoPTPV3 = async () => {
  const url = `${baseURLApi}/selections/migrateNew`;
  return axios.get(url, config).then((response) => response);
};

function unscratchHorses(item) {
  const url = `${baseURLApi}/selections/unscratchhorses`;
  return axios.post(url, item, config).then((response) => response.data);
}

function loadVenueRaces(selectedDate) {
  const url = `${baseURLApi}/selections/loadvenueraces`;
  return axios
    .post(url, selectedDate, config)
    .then((response) => response.data);
}

function loadPointDetails(item) {
  const url = `${baseURLApi}/selections/loadpointsdetails`;
  return axios.post(url, item, config).then((response) => response.data);
}

function horseByRace(item) {
  const url = `${baseURLApi}/selections/horsebyrace`;
  return axios.post(url, item, config).then((response) => response.data);
}

function scratchedHorses(item) {
  const url = `${baseURLApi}/selections/scratchedhorses`;
  return axios.post(url, item, config).then((response) => response.data);
}

function pointsValues(data) {
  const url = `${baseURLApi}/selections/pointsvalues`;
  return axios.post(url, data, config).then((response) => response.data);
}

function formingWithoutNA(data) {
  const url = `${baseURLApi}/selections/formingwithoutna`;
  return axios.post(url, data, config).then((response) => response.data);
}

function getRaceByDate(date) {
  const url = `${baseURLApi}/selections/racebydate`;
  let data = { date: date };
  return axios.post(url, data, config).then((response) => response.data);
}

function saveRaceInfo(data) {
  const url = `${baseURLApi}/selections/saveraceinfo`;
  return axios.post(url, data, config).then((response) => response.data);
}
function updateHorsesSelectedPace(data) {
  const url = `${baseURLApi}/selections/updatehorseselectedpace`;
  return axios.post(url, data, config).then((response) => response.data);
}

function loadVenues() {
  const url = `${baseURLApi}/selections/loadvenues`;
  return axios.get(url, config).then((response) => response.data);
}

function loadVenuesWithMaps() {
  const url = `${baseURLApi}/selections/loadVenuesWithMaps`;
  return axios.get(url, config).then((response) => response.data);
}

function loadRailPosFromTrackCode(data) {
  const url = `${baseURLApi}/selections/loadrailposfromtc`;
  return axios.post(url, data, config).then((response) => response.data);
}

function loadSavedRailPosition(data) {
  const url = `${baseURLApi}/selections/loadsavedrp`;
  return axios.post(url, data, config).then((response) => response.data);
}

function deleteRailPos(data) {
  const url = `${baseURLApi}/selections/deleterp`;
  return axios.post(url, data, config).then((response) => response.data);
}

function saveRP(data) {
  const url = `${baseURLApi}/selections/saverp`;
  return axios.post(url, data, config).then((response) => response.data);
}

function savePrices(data) {
  const url = `${baseURLApi}/selections/saveprices`;
  return axios.post(url, data, config).then((response) => response.data);
}

function deleteForming(data) {
  const url = `${baseURLApi}/selections/deleteforming`;
  return axios.post(url, data, config).then((response) => response.data);
}

function reassingFormingPer() {
  const url = `${baseURLApi}/selections/reassignformingper`;
  return axios.get(url, config).then((response) => response.data);
}

function uploadPointsNewPTP() {
  const url = `${baseURLApi}/selections/uploadnewptppoints`;
  return axios.get(url, config).then((response) => response.data);
}

function setGenerationTime(race) {
  const url = `${baseURLApi}/selections/setgentime`;
  return axios.post(url, race, config).then((response) => response.data);
}

async function getJockeyPremiership() {
  const url = `${baseURLApi}/selections/getJockeyPremiership`;
  return axios.get(url, config).then((response) => response.data);
}

function getJockey(id) {
  const url = `${baseURLApi}/selections/getJockey`;
  return axios.post(url, id, config).then((response) => response.data);
}

function updateJockey(jockey) {
  const url = `${baseURLApi}/selections/updateJockey`;
  return axios.post(url, jockey, config).then((response) => response.data);
}

function createJockey(jockey) {
  const url = `${baseURLApi}/selections/createJockey`;
  return axios.post(url, jockey, config).then((response) => response.data);
}

async function deleteJockey(id) {
  const url = `${baseURLApi}/selections/deleteJockey`;
  return axios.post(url, id, config).then((response) => response.data);
}

async function getVenueByTC(data) {
  const url = `${baseURLApi}/selections/getVenueByTC`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function getLastRailPos(data) {
  const url = `${baseURLApi}/selections/getLastRailPos`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function listVenueComments(data) {
  const url = `${baseURLApi}/selections/listVenueComments`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function venueMessage(data) {
  const url = `${baseURLApi}/selections/venueMessage`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function pm2Restart(data) {
  const url = `${baseURLApi}/pm2Restart`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function pm2RestartAll() {
  const url = `${baseURLApi}/pm2RestartAll`;
  return await axios.get(url, config).then((response) => response.data);
}

async function pm2Stop(data) {
  const url = `${baseURLApi}/pm2Stop`;
  return await axios.post(url, data, config).then((response) => response.data);
}

async function pm2RunningInstances() {
  const url = `${baseURLApi}/pm2RunningInstances`;
  return await axios.get(url, config).then((response) => response.data);
}

async function DataForToday(data) {
  const url = `${ptpCrone}/updateDataForToday`;
  return await axios.post(url, data, config).then((response) => response);
}

async function DataForTomorrow(data) {
  const url = `${ptpCrone}/updateDataForTomorrow`;
  return await axios.post(url, data, config).then((response) => response);
}

async function DataForAfterTomorrow(data) {
  const url = `${ptpCrone}/updateDataForAfterTomorrow`;
  return await axios.post(url, data, config).then((response) => response);
}

async function updateVenueHistory(data) {
  const url = `${ptpCrone}/updateVenueHistory`;
  return await axios.post(url, data, config).then((response) => response);
}

async function updateProfiles(data) {
  const url = `${ptpCrone}/updateProfiles`;
  return await axios.post(url, data, config).then((response) => response);
}

async function updateFreeTips(data) {
  const url = `${ptpCrone}/updateFreeTips`;
  return await axios.post(url, data, config).then((response) => response);
}

async function fixJockeyNames(data) {
  const url = `${ptpCrone}/fixJockeyNames`;
  return await axios.post(url, data, config).then((response) => response);
}

async function updateOddsWithFlucsUbSb(data) {
  const url = `${ptpCrone}/updateOddsWithFlucsUbSb`;
  return await axios.post(url, data, config).then((response) => response.data);
}


async function updateResultsUb(data) {
  const url = `${ptpCrone}/resultsUniBet`;
  return await axios
    .post(url, data, config)
    .then((response) => response)
    .catch((err) => err.response);
}

async function userSelected(data) {
  const url = `${baseURLApi}/userSelected`;
  return await axios
    .post(url, data, config)
    .then((response) => response)
    .catch((err) => err.response);
}

async function saveAdvancedSelections(data) {
  const url = `${baseURLApi}/selections/saveAdvancedSelections`;
  return await axios
    .post(url, { data }, config)
    .then((response) => response)
    .catch((err) => err.response);
}

async function getAdvancedSelections(startDate, endDate) {
  const url = `${baseURLApi}/selections/getAdvancedSelections`;
  return await axios
    .post(url, { startDate, endDate }, config)
    .then((response) => response)
    .catch((err) => err.response);
}

async function deleteAdvancedSelections(id) {
  const url = `${baseURLApi}/selections/deleteAdvancedSelections`;
  return await axios
    .post(url, { id }, config)
    .then((response) => response)
    .catch((err) => err.response);
}

async function getAdvSel(data) {
  const url = `${baseURLApi}/selections/getAdvSel`;
  return await axios
    .post(url, { data }, config)
    .then((response) => response)
    .catch((err) => err.response);
}

async function createAdvSel(data) {
  const url = `${baseURLApi}/selections/createAdvSel`;
  return await axios
    .post(url, { data }, config)
    .then((response) => response)
    .catch((err) => err.response);
}

async function updateAdvSel(data) {
  const url = `${baseURLApi}/selections/updateAdvSel`;
  return await axios
    .post(url, { data }, config)
    .then((response) => response)
    .catch((err) => err.response);
}

const getEmailStat = async (data) => {
  const url = `${baseURLApi}/selections/getEmailStat`;
  return await axios
    .post(url, { data }, config)
    .then((response) => response)
    .catch((err) => err.response);
};

export {
  triggerpacecalcTomorrow,
  triggerPaceCalc,
  baseURLApi,
  getUsersAnalytics,
  getMarketingAnalytics,
  getUsers,
  getUsersDate,
  getUser,
  deleteUser,
  createUser,
  updateUser,
  removeProTips,
  getUsersActivity,
  getUsersFrequency,
  doFetchOnline,
  upload,
  emptyAAP,
  uploadRace,
  checkProgress,
  loginAdmin,
  loginAdminForPTPUser,
  getAdmin,
  getDailyFrequencyUsers,
  getAdmins,
  getStaffList,
  deleteAdmin,
  createAdmin,
  updateAdmin,
  retrieveLoadedVenues,
  getRaces,
  getFormings,
  getPositions,
  loadRaceForm,
  getDistances,
  jockeyPremierShip,
  assignHorseScratching,
  loadHorseUnscratched,
  savePoints,
  best4HPer,
  unscratchHorses,
  loadVenueRaces,
  loadPointDetails,
  horseByRace,
  pointsValues,
  formingWithoutNA,
  getRaceByDate,
  formingsForRaces,
  saveRaceInfo,
  updateHorsesSelectedPace,
  getUnibetDB,
  updateUnibetDB,
  fixBookieOrderDB,
  generateNADB,
  lastSignUp,
  getTopTracks,
  getAllVenues,
  deleteVenueDB,
  updateVenueDB,
  createVenueDB,
  clicksAffiliates,
  UnibetCheckEmptyId,
  updateRacesRatings,
  checkSBprices,
  getSelectedVenue,
  getvenueInfo,
  loadVenues,
  loadSavedRailPosition,
  saveRP,
  deleteRailPos,
  updateTrackCond,
  updateRaceInfo,
  updateRaceDetails,
  doFindAllInfo,
  updateHorseInfo,
  getFrequentUsers,
  savePrices,
  deleteForming,
  reassingFormingPer,
  lastSignIn,
  deletePoints,
  deleteStaff,
  addStaff,
  uploadPointsNewPTP,
  setGenerationTime,
  sendGenerationEmailAll,
  sendMaintenanceEmailsAll,
  sendVerificationEmail,
  sendResetPassEmail,
  sendPromoEmailAll,
  enableEmailButtons,
  sendFridayNightEmail,
  InsertOrUpdateRaces,
  InsertOrUpdateHorses,
  InsertLBID,
  InsertSBID,
  UpdateTimeAndTrackCoditionToday,
  LBSBDetails,
  SBODDS,
  SendTipOfTheDay,
  getEmailsSubjects,
  getContactUsEmail,
  getInboxEmails,
  GetSBDetails,
  emailPromoAnalytics,
  editEmailsSubjects,
  markEmailsRead,
  sendEmailContactUs,
  getSubPlan,
  generateSiteMap,
  cleanLocalhosts,
  generateAIJsonReport,
  UpdateNames,
  getJockeyPremiership,
  getResultsUserPref,
  scratchedHorses,
  getTracksBasedOnDate,
  getHJT,
  getOnlinePTP,
  getVisitorsData,
  doFetchVisitor,
  getVenueByTC,
  getHorsePoints,
  getLastRailPos,
  UpdateHorsesIDs,
  getVenuesState,
  updateVenueState,
  listVenueComments,
  mailChimpCreateAudience,
  getSelectedOnNow,
  assignThirdUp,
  updateOnNowRaceID,
  AssignThirdUpHorseProfile,
  loadVenuesWithMaps,
  loadRailPosFromTrackCode,
  showRaces,
  venueMessage,
  updateVenueDetails,
  getVenueDetails,
  query,
  updateRaceStatus,
  pm2Restart,
  pm2RestartAll,
  pm2Stop,
  pm2RunningInstances,
  updateOddsWithFlucsUbSb,
  DataForToday,
  DataForTomorrow,
  DataForAfterTomorrow,
  updateVenueHistory,
  updateProfiles,
  updateFreeTips,
  fixJockeyNames,
  updateResultsUb,
  getJockey,
  updateJockey,
  deleteJockey,
  createJockey,
  getResultsUserRodPref,
  userSelected,
  getClient,
  saveAdvancedSelections,
  getAdvancedSelections,
  deleteAdvancedSelections,
  getAdvSel,
  createAdvSel,
  updateAdvSel,
  loadBarriersPTP2XLS,
  loadRailPosPTP2XLS,
  loadStraightsPTP2XLS,
  getEmailStat,
  getHorsesProfile,
  getVisitorOrClientOnline,
  generateFreeTips,
  updateDevServer,
  pushtoPTPV3,
};
