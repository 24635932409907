import { toast } from 'react-toastify';
import { loginAdmin , loginAdminForPTPUser } from '../../config/config'

//import Errors from '../components/FormItems/error/errors';
//import { mockUser } from './mock';
//import axios from 'axios';
//import config from '../config';
//import jwt from "jsonwebtoken";

export const AUTH_FAILURE = 'AUTH_FAILURE';

export const AUTH_USERS_FAILURE = 'AUTH_USERS_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGIN_USERS_REQUEST = 'LOGIN_USERS_REQUEST';
export const LOGIN_USERS_SUCCESS = 'LOGIN_USERS_SUCCESS';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const PASSWORD_RESET_EMAIL_REQUEST = 'PASSWORD_RESET_EMAIL_REQUEST';
export const PASSWORD_RESET_EMAIL_SUCCESS = 'PASSWORD_RESET_EMAIL_SUCCESS';

export const AUTH_INIT_SUCCESS = 'AUTH_INIT_SUCCESS';
export const AUTH_INIT_ERROR = 'AUTH_INIT_ERROR';

export const AUTH_USERS_INIT_SUCCESS = 'AUTH_USERS_INIT_SUCCESS';
export const AUTH_USERS_INIT_ERROR = 'AUTH_USERS_INIT_ERROR';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

function selectErrorMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.error && data.error.message) {
      return data.error.message;
    }

    return String(data);
  }

  return error.message || 'ERROR';
}

export default function loginUser(creds, history) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
    });

    if (creds.email.length > 0 && creds.password.length > 0) {
      loginAdmin(creds).then(res => {


        dispatch(receiveToken(res));
        dispatch(doInit());
        //history.push('/admin');

      }).catch(err => {
        dispatch(authError('Email / Password Invalid'));
        console.log('error loginUser Email / Password Invalid ' + err)
      })
    } else {
      dispatch(authError('Something was wrong. Try again'));
    }
  };
}

export function loginForPTPClients(creds, history) {
  console.log("loginForPTPClients")
  console.log(creds)
  return (dispatch) => {
    dispatch({
      type: LOGIN_USERS_REQUEST,
    });

    if (creds.email.length > 0 && creds.password.length > 0) {
      loginAdminForPTPUser(creds).then(res => {
        if(res?.status === 200) {
console.log(res)

        dispatch(receiveTokenForPTPUsers(res?.data));
        dispatch(doInitForPTPUser());
        //history.push('/admin');
        } else {
          dispatch(authErrorForPTPUser('Email / Password Invalid'));
          console.log('error loginUser Email / Password Invalid ')
        }
      }).catch(err => {
        dispatch(authErrorForPTPUser('Email / Password Invalid'));
        console.log('error loginUser Email / Password Invalid ' + err)
      })
    } else {
      dispatch(authErrorForPTPUser('Something was wrong. Try again'));
    }
  };
}


/*async function findMe(token) {
  let tok = JSON.parse(token)
  try {
    await loginAdmin({ email: tok.email, password: tok.password }).then(res => {
      if (res.email == tok.email) {
        let response = JSON.stringify(res)
        return response
      } else {
        return null
      }
    })
  } catch (error) {
    console.log(error)
  }
}*/

export function authError(payload) {
  return {
    type: AUTH_FAILURE,
    payload,
  };
}

export function authErrorForPTPUser(payload) {
  return {
    type: AUTH_USERS_FAILURE,
    payload,
  };
}

export function doInit() {
  return async (dispatch) => {
    try {
      let currentUser = null;
      //localStorage.removeItem('ptpAdminToken');
      let token = localStorage.getItem('ptpAdminToken');
      if (token) {
        let tok = JSON.parse(token)
        await loginAdmin({ email: tok.email, password: tok.password }).then(res => {
          if (res.email === tok.email) {
            currentUser = JSON.stringify(res)
          }
        })
      }
      dispatch({
        type: AUTH_INIT_SUCCESS,
        
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))
      console.log('Do init error ' + error)
      dispatch({
        type: AUTH_INIT_ERROR,
        payload: error,
      });
    }

  }
}

export function doInitForPTPUser() {
  return async (dispatch) => {
    try {
      let currentUser = null;
      //localStorage.removeItem('ptpAdminToken');
      let token = localStorage.getItem('ptpAdminToken');
      if (token) {
        let tok = JSON.parse(token)
        await loginAdminForPTPUser({ email: tok.email, password: tok.password }).then(res => {
          console.log(res)
          if (res.data.email === tok.email) {
            currentUser = JSON.stringify(res.data)
          }
        })
      }
      dispatch({
        type: AUTH_USERS_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      toast.error(selectErrorMessage(error))
      console.log('Do init error ' + error)
      dispatch({
        type: AUTH_USERS_INIT_ERROR,
        payload: error,
      });
    }

  }
}

export function receiveToken(token) {
  return (dispatch) => {
    try {
      localStorage.setItem('ptpAdminToken', JSON.stringify(token));
      //axios.defaults.headers.common['Authorization'] = "Bearer " + token;
      dispatch({
        type: LOGIN_SUCCESS
      });
    } catch (err) {
      console.log('receive token error ' + err)
    }
  }
}

export function receiveTokenForPTPUsers(token) {
  return (dispatch) => {
    try {
      localStorage.setItem('ptpAdminToken', JSON.stringify(token));
      //axios.defaults.headers.common['Authorization'] = "Bearer " + token;
      dispatch({
        type: LOGIN_USERS_SUCCESS
      });
    } catch (err) {
      console.log('receive token error ' + err)
    }
  }
}

export function logoutUser(history) {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    localStorage.removeItem('ptpAdminToken');
    //axios.defaults.headers.common['Authorization'] = "";
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    history.push('/login')
  };
}

export function logoutPTPUser(history) {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_REQUEST,
    });
    localStorage.removeItem('ptpAdminToken');
    //axios.defaults.headers.common['Authorization'] = "";
    dispatch({
      type: LOGOUT_SUCCESS,
    });
    history.push('/login-clients')
  };
}

/*
export function verifyEmail(token) {
  return(dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      axios.put("/auth/verify-email", {token}).then(verified => {
        if (verified) {
          toast.success("Your email was verified");
        }
      }).catch(err => {
        toast.error(err.response.data);
      }).finally(() => {
        dispatch(push('/login'));
      })
    }
  }
}

export function resetPassword(token, password) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: RESET_REQUEST,
      });
      axios.put("/auth/password-reset", {token, password}).then(res => {
          dispatch({
            type: RESET_SUCCESS,
          });
          toast.success("Password has been updated");
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function sendPasswordResetEmail(email) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: PASSWORD_RESET_EMAIL_REQUEST,
      });
      axios.post("/auth/send-password-reset-email", {email}).then(res => {
        dispatch({
          type: PASSWORD_RESET_EMAIL_SUCCESS,
        });
        toast.success("Email with resetting instructions has been sent");
        dispatch(push('/login'));
      }).catch(err => {
        dispatch(authError(err.response.data));
      })
    }
  }
}

export function registerUser(creds) {
  return (dispatch) => {
    if (!config.isBackend) {
      dispatch(push('/login'));
    } else {
      dispatch({
        type: REGISTER_REQUEST,
      });

      if (creds.email.length > 0 && creds.password.length > 0) {
        axios.post("/auth/signup", creds).then(res => {
          dispatch({
            type: REGISTER_SUCCESS
          });
          toast.success("You've been registered successfully. Please check your email for verification link");
          dispatch(push('/login'));
        }).catch(err => {
          dispatch(authError(err.response.data));
        })

      } else {
        dispatch(authError('Something was wrong. Try again'));
      }
    }
  };
}
*/